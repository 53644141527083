import { call, put } from 'redux-saga/effects';
import api from 'services/api';
import { Creators } from 'store/ducks/jobs';

export function* postJob({ data }) {
  try {
    const response = yield call(api.post, '/v1/jobs', data);
    const { body } = response.data;

    yield put(Creators.setJobSuccess(body));
  } catch (e) {
    const { response } = e;
    yield put(
      Creators.setJobFailure(response.data)
    );
  }
}

export function* postJoinToJob({ data }) {
  try {
    const response = yield call(api.post, '/v1/curriculum/job', data);
    const { body } = response.data;

    yield put(Creators.postJoinToJobSuccess(body));
  } catch (e) {
    const { response } = e;
    yield put(
      Creators.setJobFailure(response.data)
    );
  }
}

export function* getJobById({ id }) {
  try {
    const response = yield call(api.get, `/v1/public/jobs?id=${id}`);
    const { body } = response.data;

    yield put(Creators.setJobSuccess(body));
  } catch (e) {
    const { response} = e;
    yield put(
      Creators.setJobFailure(response.data)
    );
  }
}

export function* getJobByKeywordsCityCode({ keywords, code }) {
  try {
    const response = yield call(api.get, `/v1/public/jobs?keywords=${keywords}&code=${code}`);
    const { body } = response.data;

    yield put(Creators.setJobSuccess(body));
  } catch (e) {
    const { response } = e;
    yield put(
      Creators.setJobFailure(response.data)
    );
  }
}

export function* getJobByPeriod({ dateFrom, dateTo }) {
  try {
    const response = yield call(api.get, `/v1/public/jobs?dateFrom=${dateFrom}&dateTo=${dateTo}`);
    const { body } = response.data;

    yield put(Creators.setJobSuccess(body));
  } catch (e) {
    const { response } = e;
    yield put(
      Creators.setJobFailure(response.data)
    );
  }
}

export function* getJobByLast({ count }) {
  try {
    const response = yield call(api.get, `/v1/public/jobs?last=${count}`);
    const { body } = response.data;

    yield put(Creators.setJobSuccess(body));
  } catch (e) {
    const { response } = e;
    yield put(
      Creators.setJobFailure(response.data)
    );
  }
}

export function* getJobByUserId({ userId }) {
  try {
    const response = yield call(api.get, `/v1/jobs?userId=${userId}`);
    const { body } = response.data;

    yield put(Creators.setJobSuccess(body));
  } catch (e) {
    const { response } = e;
    yield put(
      Creators.setJobFailure(response.data)
    );
  }
}
