import { Grid, Paper, Typography
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import ColoredLinearProgress from 'pages/components/LineProgress';
import React, { useEffect  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import avatar from 'assets/avatar_user.png';
// Actions
import { Creators } from 'store/ducks/users';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  conteiner:{
    direction: 'row', 
    justifyContent: 'center', 
    alignItems: 'center'
  }

}));

const renderLine = (label, text) => (
  <div style={{padding:10}}>
    <Typography variant="body2" color="textSecondary">
      {label}
    </Typography>
    <Typography>
      {text}
    </Typography>
  </div>
)

const renderData = (data) => {
  return (
    <Grid container spacing={3} >
      <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection: 'column' }} >
        <>
        <img src={avatar} style={{width:120, marginBottom: 5}}/>
        </>
        {renderLine('', data?.name)}
        {renderLine('', data?.email)}
      </Grid>
    </Grid>
  )
}

const Index = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.signin);
  const { pending, data } = useSelector(state => state.users);

  useEffect(() => {
    if(user && user?.id) {
      dispatch(Creators.fetchUser(user?.id));
    }
  },[user]);

  return (
    <div>
      {pending ? <ColoredLinearProgress /> : null }
      <Grid className={classes.conteiner} container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            {
              renderData(data)
            }

          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default Index