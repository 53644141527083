import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import { Paper, Typography, Box, Grid, Card, CardContent, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

import Menu from "pages/components/menu";

import { Title } from "pages/components/styles";

// Actions
import { Creators } from 'store/ducks/reports';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fixedHeight: {
    minheight: 240,
  },
}));

function Index(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const { curriculums, jobs } = useSelector(state => state.reports);

  useEffect(() => {
    dispatch(Creators.fetchCurriculumsTotals());
    dispatch(Creators.fetchJobsTotals());
  },[]);

  return (
    <Menu>
      <div style={{height: "20px"}} />
      <Title big>Vagas</Title>
      <Paper className={fixedHeightPaper}>
        <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Card sx={{ display: 'flex' }} variant='outlined'>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography variant="subtitle1" color="textSecondary" component="div">
                      Total Geral
                    </Typography>
                    <Typography component="div" variant="h3">
                      {jobs?.pending && <CircularProgress size="1.4rem" style={{color:'#252525'}} />}
                      {!jobs?.pending && (jobs?.data?.grandTotal || 0)}
                    </Typography>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card sx={{ display: 'flex' }} variant='outlined'>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography variant="subtitle1" color="textSecondary" component="div">
                      Este Ano
                    </Typography>
                    <Typography component="div" variant="h3">
                      {jobs?.pending && <CircularProgress size="1.4rem" style={{color:'#252525'}} />}
                      {!jobs?.pending && (jobs?.data?.yearTotal || 0)}
                    </Typography>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card sx={{ display: 'flex' }} variant='outlined'>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography variant="subtitle1" color="textSecondary" component="div">
                      Mês Atual
                    </Typography>
                    <Typography component="div" variant="h3">
                      {jobs?.pending && <CircularProgress size="1.4rem" style={{color:'#252525'}} />}
                      {!jobs?.pending && (jobs?.month?.grandTotal || 0)}
                    </Typography>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card sx={{ display: 'flex' }} variant='outlined'>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography variant="subtitle1" color="textSecondary" component="div">
                      Hoje
                    </Typography>
                    <Typography component="div" variant="h3">
                      {jobs?.pending && <CircularProgress size="1.4rem" style={{color:'#252525'}} />}
                      {!jobs?.pending && (jobs?.data?.dayTotal || 0)}
                    </Typography>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          </Grid>
      </Paper>

      <div style={{height: "20px"}} />
      <Title big>Currículos</Title>
      <Paper className={fixedHeightPaper}>
        <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Card sx={{ display: 'flex' }} variant='outlined'>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography variant="subtitle1" color="textSecondary" component="div">
                      Total Geral
                    </Typography>
                    <Typography component="div" variant="h3">
                      {curriculums?.pending && <CircularProgress size="1.4rem" style={{color:'#252525'}} />}
                      {!curriculums?.pending && (curriculums?.data?.grandTotal || 0)}
                    </Typography>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card sx={{ display: 'flex' }} variant='outlined'>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography variant="subtitle1" color="textSecondary" component="div">
                      Este Ano
                    </Typography>
                    <Typography component="div" variant="h3">
                      {curriculums?.pending && <CircularProgress size="1.4rem" style={{color:'#252525'}} />}
                      {!curriculums?.pending && (curriculums?.data?.yearTotal || 0)}
                    </Typography>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card sx={{ display: 'flex' }} variant='outlined'>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography variant="subtitle1" color="textSecondary" component="div">
                      Mês Atual
                    </Typography>
                    <Typography component="div" variant="h3">
                      {curriculums?.pending && <CircularProgress size="1.4rem" style={{color:'#252525'}} />}
                      {!curriculums?.pending && (curriculums?.data?.monthTotal || 0)}
                    </Typography>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card sx={{ display: 'flex' }} variant='outlined'>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography variant="subtitle1" color="textSecondary" component="div">
                      Hoje
                    </Typography>
                    <Typography component="div" variant="h3">
                      {curriculums?.pending && <CircularProgress size="1.4rem" style={{color:'#252525'}} />}
                      {!curriculums?.pending && (curriculums?.data?.dayTotal || 0)}
                    </Typography>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          </Grid>
      </Paper>
    </Menu>
  );
}

export default Index;
