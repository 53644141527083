import { call, put } from 'redux-saga/effects';
import api from 'services/api';
import { Creators } from 'store/ducks/signup';

export function* postSignupUser({ data }) {
  console.log('store::sagas::signup::postUser', data);
  try {
    const response = yield call(api.post, '/v1/user/signup', data);
    const { body } = response.data;

    yield put(Creators.setSignupSuccess(body));
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.setSignupFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}
