import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@material-ui/core";

import { Title, Text } from "pages/components/styles";
import Header from "pages/components/Header";
import Footer from "pages/components/Footer";
import Button from "pages/components/Button";
import { Card } from "pages/components/styles";
import SigninSymbol from "assets/icons/signin-success.png";

import { MainContent } from "./styles";

function Index(props) {
  const navigate = useNavigate();

  return <div>
    <Header />

    <MainContent bgColor={'#FBFBFB'}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card style={{textAlign:'center'}}>
            <Title center bigger color="#15363F">Cadastro realizado com sucesso!</Title>
            <Text center>
              Clique no botão abaixo para acessa painel do usuário.
            </Text>
            {/* <Text center>
              Verifique a sua caixa de e-mail para ativação da sua conta na Conect Clinic. Caso não encontre-o, verifique também  a caixa de spam.
            </Text> */}

            <Button
              fullWidth
              onClick={() => navigate('/login')}
              bgColor="#3FB992"
              text={'E N T R A R'}
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={6} style={{textAlign:'center', alignSelf:'center'}}>
          <img src={SigninSymbol} style={{width: '100%', maxWidth:'310px', maxHeight:'290px'}} />
        </Grid>
      </Grid>

    </MainContent>

    <Footer />
  </div>;
}

export default Index;
