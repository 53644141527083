import moment from 'moment-timezone';
import "moment/locale/pt-br";
import MomentUtils from "@date-io/moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Paper, LinearProgress, TextField } from "@material-ui/core";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { DataGrid } from "@material-ui/data-grid";
import Box from '@material-ui/core/Box'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogQuest from "pages/components/DialogQuest";

import CustomButton from "pages/components/Button";
import { Title } from "pages/components/styles";
import Menu from "pages/components/menu";
import CurriculumView from "pages/curriculums/view";

// Actions
import { Creators } from "store/ducks/users";

//style ustilizado
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },

  user: {
    display: "flex",
    flexDirection: "column",
    width: 300,
    height: "auto",
  },
  vCenter:{
    display: 'flex',
    alignItems: 'center'
  },
}));

const Index = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pending, users, data, modified } = useSelector((state) => state.users);
  const [selectedItem, setSelectedItem] = useState(null);

  const [filterType, setFilterType] = useState(0);
  const [filterText, setFilterText] = useState('');
  const [selectedDateFrom, setSelectedDateFrom] = useState(moment());
  const [strSelectedDateFrom, setStrSelectedDateFrom] = useState(moment().format("DD/MM/YYYY"));
  const [selectedDateTo, setSelectedDateTo] = useState(moment());
  const [strSelectedDateTo, setStrSelectedDateTo] = useState(moment().format("DD/MM/YYYY"));

  const [selectedCurriculum, setSelectedCurriculum] = useState(null);

  const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));
  const [openModal, setOpenModal] = useState(false);

  const [questionOpened, setQuestionOpened] = useState(false);
  
  const onQuestionClose = () => setQuestionOpened(false);

  useEffect(() => {
    // When triggered action Delete or Update, execute filter again
    if(modified === true) {
      handlerFilter();
    }
  }, [modified]);

  const handlerFilter = () => {
    if(filterType === 0){
      if(filterText.trim().length > 3) {
        dispatch(Creators.fetchUsersByName(filterText, false));
      }
    } else {
      dispatch(Creators.fetchUsersByPeriod(strSelectedDateFrom, strSelectedDateTo));
    }
  }

  const onDateChangeSelectedDateFrom = (date, value) => {
    setSelectedDateFrom(date);
    setStrSelectedDateFrom(value);
  };

  const onDateChangeSelectedDateTo = (date, value) => {
    setSelectedDateTo(date);
    setStrSelectedDateTo(value);
  };

  const dateFormatter = str => {
    return str;
  };

  const handleFilterType = (event) => {
    const value = event.target.value;
    console.log(value);
    setFilterType(value);
  };

  //remover users -> dialogo
  const handleDelete = (params) => {
    const ITEM = users.find((v) => v.id == params.row.id);
    setSelectedItem(ITEM);
    //abre o dialogo que confirma exclusão
    setQuestionOpened(true);
  };
  //remover users -> ação
  const onDeletePress = () => {
    dispatch(Creators.deleteUser(selectedItem?.id));
    setQuestionOpened(false);
  };

  const handleShowModalCurriculum = curriculum => {
    setSelectedCurriculum(curriculum);
    setOpenModal(true);
  }

  const handleModalClose = () => setOpenModal(false);

  const columns = [
    { field: "id", headerName: "Id" },
    { field: "name", headerName: "Nome", width: 200 },
    { field: "email", headerName: "Email", width: 200 },
    {
      field: "action",
      headerName: "Ações",
      width: 200,
      renderCell: (params) => (
        <>
          {
            users && params.row.curriculum ? (
            <>
              <Button
                size="small"
                type="button"
                variant="contained"
                color="primary"
                style={{ marginLeft: 8 }}
                onClick={() => handleShowModalCurriculum(params.row.curriculum)}
              >
                Curriculum
              </Button>
            </>
            ) : null
          }
          {
            users ? (
            <>
              <Button
                size="small"
                type="button"
                variant="contained"
                color="default"
                style={{ marginLeft: 8 }}
                onClick={() => handleDelete(params)}
              >
                Remover
              </Button>
            </>
            ) : null
          }
        </>
      ),
    },
  ];

  return (
    <Menu>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Title big>Usuários</Title>
        </Grid>
        <Grid item xs={12} md={8} style={{alignSelf:'center'}}>
          { (!pending && [...users].length > 0) && <Title right>{[...users].length} usuários exibidos</Title>}
        </Grid>
      </Grid>

      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel>Buscar por:</InputLabel>
                <Select
                  value={filterType}
                  onChange={handleFilterType}
                >
                  <MenuItem value={0}>Nome</MenuItem>
                  <MenuItem value={1}>Período</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>

          
          {filterType === 0 &&
            <Grid item xs={12} md={3}>
              <TextField disabled={pending}
                value={filterText}
                onChange={e => setFilterText(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label={'Nome'}
                autoFocus
              />
            </Grid>
          }

          {filterType === 1
            ? <>
                <Grid item xs={12} md={3}>
                  <div style={{padding:20}}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"pt-BR"} >
                      <KeyboardDatePicker disabled={pending}
                        disableFuture
                        autoOk={true}
                        ampm={false}
                        label="De:"
                        showTodayButton={true}
                        value={selectedDateFrom}
                        format="DD/MM/YYYY"
                        invalidDateMessage="Data em formato inválido."
                        inputValue={strSelectedDateFrom}
                        onChange={onDateChangeSelectedDateFrom}
                        rifmFormatter={dateFormatter}
                        cancelLabel="Cancelar"
                        todayLabel="Hoje"
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <div style={{padding:20}}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"pt-BR"} >
                      <KeyboardDatePicker disabled={pending}
                        disableFuture
                        autoOk={true}
                        ampm={false}
                        label="a:"
                        showTodayButton={true}
                        value={selectedDateTo}
                        format="DD/MM/YYYY"
                        invalidDateMessage="Data em formato inválido."
                        inputValue={strSelectedDateTo}
                        onChange={onDateChangeSelectedDateTo}
                        rifmFormatter={dateFormatter}
                        cancelLabel="Cancelar"
                        todayLabel="Hoje"
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Grid>
              </>
            : null
          }
          <Grid item xs={12} md={3} className={classes.vCenter}>
            <Button disabled={pending} type="button" variant="contained" color="primary"
              onClick={()=>handlerFilter()}>Filtrar</Button>
          </Grid>
        </Grid>
      </Paper>

      {pending && 
        <>
          <LinearProgress />
          <Title>Buscando dados...</Title>
        </>
      }

      <Grid container spacing={3}>
        <Grid item xs={12}>
        {(!pending && (!users || [...users].length <= 0)) &&
            <Title>Nenhum usuário encontrado.</Title>
          }
          {(!pending && (users && [...users].length > 0)) &&
            <div style={{paddingTop:'30px', paddingBottom:'30px'}}>
              <Paper className={classes.paper}>
                <DataGrid
                  rows={users}
                  columns={columns}
                  density="comfortable"
                  autoHeight={true}
                  autoPageSize={true}
                  disableColumnMenu={true}
                  hideFooterSelectedRowCount={true}
                />
              </Paper>
            </div>
          }
        </Grid>
      </Grid>

      {questionOpened ? (
        <DialogQuest
          title={"Tem certeza?"}
          message={"Essa ação não poderá ser desfeita!"}
          onConfirm={onDeletePress}
          confirmLabel={"REMOVER USUÁRIO"}
          opened={questionOpened}
          onClose={onQuestionClose}
        />
      ) : null}

      <div>
        <Dialog fullScreen={fullScreen}
            open={openModal}
            onClose={handleModalClose}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              <CustomButton outlined width={'100px'} onClick={handleModalClose} text="Voltar" />
            </DialogTitle>
            <DialogContent dividers={true}>
              <CurriculumView data={selectedCurriculum} />
            </DialogContent>
            <DialogActions>
              <CustomButton bgColor={'#909090'} onClick={handleModalClose} text="Voltar" />
            </DialogActions>
          </Dialog>
      </div>
    </Menu>
  );
};

export default Index;
