import { takeEvery } from 'redux-saga/effects';
import { Types } from 'store/ducks/jobs';
import { postJob, getJobByKeywordsCityCode, getJobByPeriod, getJobByLast, getJobByUserId, getJobById, postJoinToJob } from './jobs';

const jobsSagas = [
	takeEvery(Types.POST_JOB, postJob),
	takeEvery(Types.POST_JOIN_TO_JOB, postJoinToJob),
	takeEvery(Types.FETCH_JOB_BY_ID, getJobById),
	takeEvery(Types.FETCH_JOB_BY_KEYWORDS_CITY_CODE, getJobByKeywordsCityCode),
	takeEvery(Types.FETCH_JOB_BY_PERIOD, getJobByPeriod),
	takeEvery(Types.FETCH_JOB_BY_LAST, getJobByLast),
	takeEvery(Types.FETCH_JOB_BY_USER_ID, getJobByUserId)
]

export default jobsSagas;