export const ENV = process.env.REACT_APP_ENV;
export const BASE_URL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
    : ENV === 'dev'
    ? process.env.REACT_APP_DEV_API_BASE_URL
    : ENV === 'staging'
      ? process.env.REACT_APP_STAGING_API_BASE_URL
      : process.env.REACT_APP_API_BASE_URL;
export const MAP_API = process.env.REACT_APP_GOOGLE_GEOCODING_API;
export const STORE_KEY = process.env.REACT_APP_USER_STORE_KEY;

export const UFS = [
  {label: "Acre", value: "AC"},
  {label: "Alagoas", value: "AL"},
  {label: "Amapá", value: "AP"},
  {label: "Amazonas", value: "AM"},
  {label: "Bahia", value: "BA"},
  {label: "Ceará", value: "CE"},
  {label: "Distrito Federal", value: "DF"},
  {label: "Espírito Santo", value: "ES"},
  {label: "Goiás", value: "GO"},
  {label: "Maranhão", value: "MA"},
  {label: "Mato Grosso", value: "MT"},
  {label: "Mato Grosso do Sul", value: "MS"},
  {label: "Minas Gerais", value: "MG"},
  {label: "Pará", value: "PA"},
  {label: "Paraíba", value: "PB"},
  {label: "Paraná", value: "PR"},
  {label: "Pernambuco", value: "PE"},
  {label: "Piauí", value: "PI"},
  {label: "Rio de Janeiro", value: "RJ"},
  {label: "Rio Grande do Norte", value: "RN"},
  {label: "Rio Grande do Sul", value: "RS"},
  {label: "Rondônia", value: "RO"},
  {label: "Roraima", value: "RR"},
  {label: "Santa Catarina", value: "SC"},
  {label: "São Paulo", value: "SP"},
  {label: "Sergipe", value: "SE"},
  {label: "Tocantins", value: "TO"}
]