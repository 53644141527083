import moment from 'moment-timezone';
import "moment/locale/pt-br";
import MomentUtils from "@date-io/moment";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Paper, Grid, Button, LinearProgress, TextField } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogQuest from "pages/components/DialogQuest";

import CustomButton from "pages/components/Button";

import { Title } from "pages/components/styles";
import Menu from "pages/components/menu";
import CurriculumView from "pages/curriculums/view";

import HIRING_TYPES from "assets/data/hiring-types.json";

import { getUser } from 'services/auth';
import { Creators } from 'store/ducks/curriculums';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  conteiner:{
    fontSize: 14, 
    textAlign: 'justify'
  },
  titulo: {
    margin: 0, 
    padding: 0, 
    fontSize: 14, 
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor:'#ddd',
    color: '#000'

  },

  dadosh1: {
    fontSize: 16, 
    textAlign: 'left', 
    fontWeight: 400,
    color: '#000000DE'
  },

  dadosTitulo: {
    fontSize: 16, 
    textAlign: 'left', 
    fontWeight: 400,
    color: '#0000008A'
  },

  dadosh2:{
    fontSize: 16, 
    textAlign: 'justify', 
    fontWeight: 400,
    color: '#000000DE'
  
  },

  svg:{
    color: '#000',
    width: 50,
    height: 20
  },
  vCenter:{
    display: 'flex',
    alignItems: 'center'
  },
  btnExportCSV:{
    border: '1px solid gray',
    borderRadius: 5,
    padding: 5,
    color: 'gray',
    paddingLeft: 10,
    paddingRight: 10,
    textDecoration: 'none',
    marginRight: 10
  }
  
}));

export const Index = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pending, data, error } = useSelector(state => state.curriculums);

  const [userLogged, setUserLogged] = useState(null);
  const [filterType, setFilterType] = useState(0);
  const [filterText, setFilterText] = useState('');
  const [selectedDateFrom, setSelectedDateFrom] = useState(moment());
  const [strSelectedDateFrom, setStrSelectedDateFrom] = useState(moment().format("DD/MM/YYYY"));
  const [selectedDateTo, setSelectedDateTo] = useState(moment());
  const [strSelectedDateTo, setStrSelectedDateTo] = useState(moment().format("DD/MM/YYYY"));

  const [selectedCurriculum, setSelectedCurriculum] = useState(null);
  
  const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));
  const [openModal, setOpenModal] = useState(false);
  
  const [questionOpened, setQuestionOpened] = useState(false);

  const onQuestionClose = () => setQuestionOpened(false);

  useEffect(() => {
    const _user = getUser();
    setUserLogged(_user);
    if(!_user || !_user.hash) {
      navigate('/login');
    } else {
      // dispatch(Creators.fetchCurriculumsByUserId(_user.id));
    }
  },[]);

  const getHiringType = (id) => {
    const type = [...HIRING_TYPES].find(e => e.id == id);
    return type ? String(type.label).split(' ')[0] : "";
  }

  const handlerFilter = () => {
    if(filterType === 0){
      if(filterText.trim().length > 3) {
        dispatch(Creators.fetchCurriculumsByUserName(filterText));
      }
    } else {
      dispatch(Creators.fetchCurriculumsByPeriod(strSelectedDateFrom, strSelectedDateTo));
    }
  }

  const onDateChangeSelectedDateFrom = (date, value) => {
    setSelectedDateFrom(date);
    setStrSelectedDateFrom(value);
  };

  const onDateChangeSelectedDateTo = (date, value) => {
    setSelectedDateTo(date);
    setStrSelectedDateTo(value);
  };

  const dateFormatter = str => {
    return str;
  };

  const handleFilterType = (event) => {
    const value = event.target.value;
    console.log(value);
    setFilterType(value);
  };

  //remover users -> dialogo
  const handleDelete = (params) => {
    setSelectedCurriculum(params.row);
    //abre o dialogo que confirma exclusão
    setQuestionOpened(true);
  };
  //remover users -> ação
  const onDeletePress = () => {
    // dispatch(Creators.deleteCurriculum(selectedCurriculum?.id));
    setQuestionOpened(false);
  };

  const handleShowModalCurriculum = curriculum => {
    setSelectedCurriculum(curriculum);
    setOpenModal(true);
  }

  const handleModalClose = () => setOpenModal(false);

  const columns = [
    { field: "id", headerName: "Id" },
    { field: "name", headerName: "Nome", width: 200 },
    { field: "email", headerName: "Email", width: 300 },
    { field: "telephone", headerName: "Celular", width: 200 },
    { field: "profession", headerName: "Profissão", width: 200 },
    {
      field: "action",
      headerName: "Ações",
      width: 200,
      renderCell: (params) => (
        <>
          {
            data && params.row ? (
            <>
              <Button
                size="small"
                type="button"
                variant="contained"
                color="primary"
                style={{ marginLeft: 8 }}
                onClick={() => handleShowModalCurriculum(params.row)}
              >
                Curriculum
              </Button>
            </>
            ) : null
          }
          {/* {
            data ? (
            <>
              <Button
                size="small"
                type="button"
                variant="contained"
                color="default"
                style={{ marginLeft: 8 }}
                onClick={() => handleDelete(params)}
              >
                Remover
              </Button>
            </>
            ) : null
          } */}
        </>
      ),
    },
  ];

  return ( userLogged && 
    <Menu>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Title big>Currículos</Title>
        </Grid>
        <Grid item xs={12} md={8} style={{alignSelf:'center'}}>
          { (!pending && [...data].length > 0) && <Title right>{[...data].length} currículos exibidos</Title>}
        </Grid>
      </Grid>

      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel>Buscar por:</InputLabel>
                <Select
                  value={filterType}
                  onChange={handleFilterType}
                >
                  <MenuItem value={0}>Nome</MenuItem>
                  <MenuItem value={1}>Período</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>

          
          {filterType === 0 &&
            <Grid item xs={12} md={3}>
              <TextField disabled={pending}
                value={filterText}
                onChange={e => setFilterText(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label={'Nome'}
                autoFocus
              />
            </Grid>
          }

          {filterType === 1
            ? <>
                <Grid item xs={12} md={3}>
                  <div style={{padding:20}}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"pt-BR"} >
                      <KeyboardDatePicker disabled={pending}
                        disableFuture
                        autoOk={true}
                        ampm={false}
                        label="De:"
                        showTodayButton={true}
                        value={selectedDateFrom}
                        format="DD/MM/YYYY"
                        invalidDateMessage="Data em formato inválido."
                        inputValue={strSelectedDateFrom}
                        onChange={onDateChangeSelectedDateFrom}
                        rifmFormatter={dateFormatter}
                        cancelLabel="Cancelar"
                        todayLabel="Hoje"
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <div style={{padding:20}}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"pt-BR"} >
                      <KeyboardDatePicker disabled={pending}
                        disableFuture
                        autoOk={true}
                        ampm={false}
                        label="a:"
                        showTodayButton={true}
                        value={selectedDateTo}
                        format="DD/MM/YYYY"
                        invalidDateMessage="Data em formato inválido."
                        inputValue={strSelectedDateTo}
                        onChange={onDateChangeSelectedDateTo}
                        rifmFormatter={dateFormatter}
                        cancelLabel="Cancelar"
                        todayLabel="Hoje"
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Grid>
              </>
            : null
          }
          <Grid item xs={12} md={3} className={classes.vCenter}>
            <Button disabled={pending} type="button" variant="contained" color="primary"
              onClick={()=>handlerFilter()}>Filtrar</Button>
          </Grid>
        </Grid>
      </Paper>
      
      {pending && 
        <>
          <LinearProgress />
          <Title>Buscando dados...</Title>
        </>
      }

      <Grid container spacing={3}>
        <Grid item xs={12}>
          {(!pending && (!data || [...data].length <= 0)) &&
            <Title>Nenhuma vaga encontrada.</Title>
          }
          {(!pending && (data && [...data].length > 0)) &&
            <div style={{paddingTop:'30px', paddingBottom:'30px'}}>
              <Paper className={classes.paper}>
                <DataGrid
                  rows={data}
                  columns={columns}
                  density="comfortable"
                  autoHeight={true}
                  autoPageSize={true}
                  disableColumnMenu={true}
                  hideFooterSelectedRowCount={true}
                />
              </Paper>
            </div>
          }
        </Grid>
      </Grid>

      {questionOpened ? (
        <DialogQuest
          title={"Tem certeza?"}
          message={"Essa ação não poderá ser desfeita!"}
          onConfirm={onDeletePress}
          confirmLabel={"REMOVER USUÁRIO"}
          opened={questionOpened}
          onClose={onQuestionClose}
        />
      ) : null}

      <div>
        <Dialog fullScreen={fullScreen}
            open={openModal}
            onClose={handleModalClose}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              <CustomButton outlined width={'100px'} onClick={handleModalClose} text="Voltar" />
            </DialogTitle>
            <DialogContent dividers={true}>
              <CurriculumView data={selectedCurriculum} />
            </DialogContent>
            <DialogActions>
              <CustomButton bgColor={'#909090'} onClick={handleModalClose} text="Voltar" />
            </DialogActions>
          </Dialog>
      </div>
    </Menu>
  );
}

export default Index
