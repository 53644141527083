import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Paper, TextField, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";

import DialogInfo from "pages/components/DialogInfo";
import DialogQuest from "pages/components/DialogQuest";
import { Title } from "pages/components/styles";
import Menu from "pages/components/menu";

// Actions
import { Creators } from "store/ducks/users";
import { isValidMail, isValidCellphone } from "services/helpers";

//style ustilizado
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },

  user: {
    display: "flex",
    flexDirection: "column",
    width: 300,
    height: "auto",
  },
}));

const Index = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pending, users, data, modified } = useSelector((state) => state.users);
  const [questionOpened, setQuestionOpened] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editOpened, setEditOpened] = useState(false);
  const [editAdd, setAddOpened] = useState(false);
  const [infoOpened, setInfoOpened] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");

  const onQuestionClose = () => setQuestionOpened(false);

  useEffect(() => {
    dispatch(Creators.fetchUsersConectClinic());
  }, []);
  
  useEffect(() => {
    if((data && data.id) || modified){
      dispatch(Creators.fetchUsersConectClinic());
    }
  }, [data, modified]);

  //criar novos users -> ação
  const onAddPress = (e) => {
    e.preventDefault();

    if(password.trim().length < 6) return

    if(password.trim() != repassword.trim()) return

    const _names = name.trim().split(' ');
    if(_names.length < 2) return
    
    if(!isValidMail(email)) return

    dispatch(
      Creators.postUser({
        name: name,
        email: email,
        password: password,
        user_type_id: 3,
        type: 1
      })
    );
    setAddOpened(false);
  };

  //criar novos users -> dialogo
  const onQuestionAddClose = () => {
    setAddOpened(false);
    setName("");
    setEmail("");
    setPassword("");
    setRepassword("");
  };

  //criar novos users -> dialogo
  const handleAdd = (params) => {
    setAddOpened(true);
  };

  //editar users -> dialogo
  const handleEdit = (params) => {
    const ITEM = users.find((v) => v.id == params.row.id);
    setSelectedItem(ITEM);
    setName(ITEM?.name);
    setEmail(ITEM?.email);
    setPassword("");
    setRepassword("");
    setEditOpened(true);
  };

  //editar users -> ação
  const handleEditConfirm = (e) => {
    dispatch(
      Creators.putUser({
        id: selectedItem?.id,
        name: name,
      })
    );
    setEditOpened(false);
  };

  //editar users -> dialogo
  const onEditClose = () => {
    setEditOpened(false);
    setName("");
    setEmail("");
    setPassword("");
    setRepassword("");
  };

  //remover users -> dialogo
  const handleDelete = (params) => {
    setSelectedItem(params.row);
    //abre o dialogo que confirma exclusão
    setQuestionOpened(true);
  };
  //remover users -> ação
  const onDeletePress = (params) => {
    dispatch(Creators.deleteUser(selectedItem?.id));
    setQuestionOpened(false);
  };

  const columns = [
    { field: "id", headerName: "Id" },
    { field: "name", headerName: "Nome", width: 200 },
    { field: "email", headerName: "Email", width: 200 },
    { 
      field: "type", 
      headerName: "Tipo", 
      width: 100,
      renderCell: (params) => {
        const {type} = params.row;
        return (
          type === 1 ? "Usuário" : "Admin"
        )
      }
    },
    {
      field: "action",
      headerName: "Ações",
      width: 200,
      renderCell: (params) => (
        <>
          {users ? (
            <>
              <Button
                size="small"
                type="button"
                variant="contained"
                color="default"
                onClick={() => handleEdit(params)}
              >
                Editar
              </Button>

              <Button
                size="small"
                type="button"
                variant="contained"
                color="secondary"
                style={{ marginLeft: 8 }}
                onClick={() => handleDelete(params)}
              >
                Remover
              </Button>
            </>
          ) : null}
        </>
      ),
    },
  ];

  return (
    <Menu>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Title big>Conect Clinic - Usuários</Title>
        </Grid>
        <Grid item xs={12} md={6} style={{alignSelf:'center'}}>
          <Button type="button"
            size="small"
            variant="contained"
            color="default"
            onClick={() => handleAdd()}
          >
            Adicionar Usuário
          </Button>
        </Grid>
      </Grid>

      {pending && 
        <>
          <LinearProgress />
          <Title>Buscando dados...</Title>
        </>
      }

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <DataGrid
              rows={users}
              columns={columns}
              density="comfortable"
              autoHeight={true}
              autoPageSize={true}
              disableColumnMenu={true}
              hideFooterSelectedRowCount={true}
            />
          </Paper>
        </Grid>
      </Grid>

      {editOpened ? (
        <DialogQuest
          title={"Atualizar Dados"}
          fullWidth
          onConfirm={handleEditConfirm}
          confirmLabel={"SALVAR ALTERAÇÕES"}
          opened={editOpened}
          onClose={onEditClose}
        >
          <div className={classes.user}>
            <TextField variant="outlined" margin="normal" required fullWidth
              label="Nome e sobrenome"
              disabled={pending}
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoFocus
            />
          </div>
        </DialogQuest>
      ) : null}

      {editAdd ? (
        <DialogQuest
          title={"Novo Usuário"}
          fullWidth
          onConfirm={onAddPress}
          confirmLabel={"SALVAR"}
          opened={editAdd}
          onClose={onQuestionAddClose}
        >
          {pending ? "Aguarde..." : ""}
          <div className={classes.add}>
            <TextField variant="outlined" margin="normal" required
              label="Nome e sobrenome"
              fullWidth
              disabled={pending}
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoFocus
            />

            <TextField type="email" variant="outlined" margin="normal" required
              label="Email"
              fullWidth
              disabled={pending}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField type="password" variant="outlined" margin="normal" required
              label="Senha"
              fullWidth
              disabled={pending}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <TextField type="password" variant="outlined" margin="normal" required
              label="Repetir Senha"
              fullWidth
              disabled={pending}
              value={repassword}
              onChange={(e) => setRepassword(e.target.value)}
            />
            <br />
          </div>
        </DialogQuest>
      ) : null}
      {infoOpened ? (
        <DialogInfo
          title={"Mensagem"}
          message={"Necessário preencher todos os campos!"}
          opened={infoOpened}
          onClose={() => setInfoOpened(false)}
        />
      ) : null}

      {questionOpened ? (
        <DialogQuest
          title={"Tem certeza?"}
          message={"Essa ação não poderá ser desfeita!"}
          onConfirm={onDeletePress}
          confirmLabel={"REMOVER USUÁRIO"}
          opened={questionOpened}
          onClose={onQuestionClose}
        />
      ) : null}
    </Menu>
  );
};

export default Index;
