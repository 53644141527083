import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { TextField, Grid, LinearProgress } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

import { Title } from "pages/components/styles";
import Header from "pages/components/Header";
import Footer from "pages/components/Footer";
import Button from "pages/components/Button";
import { Card } from "pages/components/styles";
import SigninSymbol from "assets/icons/signin-symbol.png";

import { MainContent } from "./styles";

// Actions
import { Creators } from 'store/ducks/users';

const useStyles = makeStyles((theme) => ({
  textField: {
    borderColor:'#FFFFFF',
    border: '0px',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    marginRight: '5px',
  }
}));

function Index(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { code } = useParams();

  const { pending, data, error } = useSelector(state => state.users);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    console.log('code', code);
  },[code]);

  useEffect(() => {
    console.log('data, error', data, error);
    if(data && data === "SUCCESS") {
      navigate('/recover/success');
    }
  },[data, error]);


  const handleSubmit = (e) => {
    e.preventDefault();
    setIsValid(password === rePassword);

    if(password !== rePassword){
      return;
    }

    const newPassword = { email, password, code };
    dispatch(Creators.putRecoverPassword(newPassword));
  }

  return <div>
    <Header />

    <MainContent bgColor={'#FBFBFB'}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            {pending && <LinearProgress />}
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Title bigger center>Criar nova senha</Title>
                </Grid>
              </Grid>
              {error && 
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Title color="#7d0408" center>Verifique os dados inseridos e tente novamente</Title>
                  </Grid>
                </Grid>
              }
              {!isValid && 
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Title color="#7d0408" center>As senhas digitadas não são iguais!</Title>
                  </Grid>
                </Grid>
              }
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <TextField disabled={pending} variant="outlined" fullWidth
                    value={email} required
                    type="email"
                    onChange={e => setEmail(e.target.value)} 
                    placeholder="Confirme seu e-mail"
                    className={classes.textField}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <TextField disabled={pending} variant="outlined" fullWidth
                    value={password} required
                    type="password" autoComplete="off"
                    onChange={e => setPassword(e.target.value)} 
                    placeholder="Senha"
                    className={classes.textField}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <TextField disabled={pending} variant="outlined" fullWidth
                    value={rePassword} required
                    type="password" autoComplete="off"
                    onChange={e => setRePassword(e.target.value)} 
                    placeholder="Confirme a senha"
                    className={classes.textField}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Button disabled={pending}
                    fullWidth
                    type="submit"
                    bgColor="#3FB992"
                    text={'Continuar'}
                  />
                </Grid>
              </Grid>
            </form>
            {pending && <LinearProgress />}
          </Card>
        </Grid>

        <Grid item xs={12} md={6} style={{textAlign:'center', alignSelf:'center'}}>
          <img src={SigninSymbol} style={{width: '100%', maxWidth:'310px', maxHeight:'290px'}} />
        </Grid>
      </Grid>

    </MainContent>

    <Footer />
  </div>;
}

export default Index;
