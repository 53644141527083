import styled from "styled-components";

export const Container = styled.div`
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  border-radius: 5px;
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  ${props => props.onClick ? {cursor:'pointer'} : {} }
  &:hover{
    background: ${props => props.onClick ? '#00000010' : '#FFFFFF'};
  }
  &:active{
    background: ${props => props.onClick ? '#00000020' :'#FFFFFF'};
  }
`;
