import { call, put } from 'redux-saga/effects';
import api from 'services/api';
import { Creators } from 'store/ducks/reports';

export function* getCurriculumsTotals() {
  try {
    const response = yield call(api.get, "/v1/curriculums/reports");
    const { body } = response.data;
  
    yield put(Creators.fetchCurriculumsTotalsSuccess(body));
  } catch (e) {
    const { response } = e;
    yield put(Creators.fetchCurriculumsTotalsFailure(response.data));
  }
}

export function* getJobsTotals() {
  try {
    const response = yield call(api.get, "/v1/jobs/reports");
    const { body } = response.data;
  
    yield put(Creators.fetchJobsTotalsSuccess(body));
  } catch (e) {
    const { response } = e;
    yield put(Creators.fetchJobsTotalsFailure(response.data));
  }
}
