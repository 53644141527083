import styled from "styled-components";

export const Container = styled.button`
  width: ${props => props.width ? props.width : '100%'};
  display: flex;
  background: ${ props => props.outlined ? 'transparent' : 'rgba(0, 0, 0, 0.3)'};
  border-width: ${props => props.outlined ? '1px' : '0px'};
  border-style: solid;
  border-color: ${props => props.outlined ? '#15363F' : 'rgba(115, 115, 115, 0.2)'};
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 1px;
  cursor: pointer;
  ${props => props.marginLeft ? {marginLeft: props.marginLeft}  : {} };
  ${props => props.marginRight ? {marginRight: props.marginRight}  : {} };
  &:hover{
    background: ${props => props.outlined ? '#15363F40' : 'rgba(0, 0, 0, 0.6)'};
  }
  &:active{
    background: ${props => props.outlined ? '#15363F80' :'rgba(0, 0, 0, 0.2)'};
  }
`;
