import styled from "styled-components";
import MetaCompanies from "assets/icons/companies.png";
import MetaCurrency from "assets/icons/currency.png";
import MetaJobs from "assets/icons/jobs.png";
import MetaSignupFree from "assets/icons/signup-free.png";
import MetaHeartGray from "assets/icons/heart-gray.png";
import MetaPinGray from "assets/icons/pin-gray.png";
import MetaFolderGray from "assets/icons/folder-gray.png";

export const IconModel = styled.img`
  width: 25px;
  height: 25px;
  margin: 5px;
`;

export const IconModelSmall = styled.img`
  width: 14px;
`;

export const IconCompanies = styled(IconModel).attrs({
  src: `${MetaCompanies}`
})``;

export const IconCurrency = styled(IconModel).attrs({
  src: `${MetaCurrency}`
})``;

export const IconJobs = styled(IconModel).attrs({
  src: `${MetaJobs}`
})``;

export const IconSignupFree = styled(IconModel).attrs({
  src: `${MetaSignupFree}`
})``;

export const IconHeartGray = styled(IconModelSmall).attrs({
  src: `${MetaHeartGray}`
})``;

export const IconPinGray = styled(IconModelSmall).attrs({
  src: `${MetaPinGray}`
})``;

export const IconFolderGray = styled(IconModelSmall).attrs({
  src: `${MetaFolderGray}`
})``;
