import { takeEvery } from 'redux-saga/effects';
import { Types } from 'store/ducks/users';
import { getUser, getOnlyConectClinicUsers, getUserByName, getUserByPeriod, insertUser, removeUser, updateUser, recoverPassword } from './users';

const usersSagas = [
	takeEvery(Types.FETCH_USER, getUser),
	takeEvery(Types.FETCH_USERS_CONECT_CLINIC, getOnlyConectClinicUsers),
	takeEvery(Types.FETCH_USERS_BY_NAME, getUserByName),
	takeEvery(Types.FETCH_USERS_BY_PERIOD, getUserByPeriod),

	takeEvery(Types.POST_USER, insertUser),
	takeEvery(Types.POST_USER_SUCCESS, () => {
  }),
	takeEvery(Types.PUT_USER, updateUser),
	takeEvery(Types.PUT_USER_SUCCESS, () => {
  }),
	takeEvery(Types.PUT_RECOVER_PASSWORD, recoverPassword),
	takeEvery(Types.PUT_USER_SUCCESS, () => {
  }),
	takeEvery(Types.DELETE_USER, removeUser),
	takeEvery(Types.DELETE_USER_SUCCESS, () => {
  })
]

export default usersSagas;