import { takeEvery } from 'redux-saga/effects';
import { Types } from 'store/ducks/reports';
import { getCurriculumsTotals, getJobsTotals } from './reports';

const reportsSagas = [
	takeEvery(Types.FETCH_CURRICULUMS_TOTALS, getCurriculumsTotals),
	takeEvery(Types.FETCH_JOBS_TOTALS, getJobsTotals)
]

export default reportsSagas;
