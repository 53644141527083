import { takeEvery } from 'redux-saga/effects';
import { Types } from 'store/ducks/curriculums';
import { postCurriculum, getCurriculums, getCurriculumByUserId, getCurriculumByUserName, getCurriculumsByPeriod } from './curriculums';

const curriculumsSagas = [
	takeEvery(Types.POST_CURRICULUM, postCurriculum),
	takeEvery(Types.FETCH_CURRICULUMS, getCurriculums),
	takeEvery(Types.FETCH_CURRICULUMS_BY_USER_ID, getCurriculumByUserId),
	takeEvery(Types.FETCH_CURRICULUMS_BY_USER_NAME, getCurriculumByUserName),
	takeEvery(Types.FETCH_CURRICULUMS_BY_PERIOD, getCurriculumsByPeriod)
]

export default curriculumsSagas;