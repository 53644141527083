import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  fetchCurriculumsTotals: [],
  fetchCurriculumsTotalsSuccess: ['data'],
  fetchCurriculumsTotalsFailure: ['error'],
  fetchJobsTotals: [],
  fetchJobsTotalsSuccess: ['data'],
  fetchJobsTotalsFailure: ['error']
});

/**
 * Handlers
 */
const STATE = {
  curriculums:{
    pending: false,
    data: [],
    error: null
  },
  jobs:{
    pending: false,
    data: [],
    error: null
  }
}
const INITIAL_STATE = STATE;

// Curriculums Totalizers
const getCurriculumsTotals = state => {
  return {...state, curriculums: {...state.curriculums, pending:true, error:null}};
};
const getCurriculumsTotalsSuccess = (state, { data }) => {
  return {...state, curriculums: {...state.curriculums, pending:false, data, error:null}};
};
const getCurriculumsTotalsFailure = (state, { error }) => {
  return {...state, curriculums: {...state.curriculums, pending:false, error} };
};

// Jobs Totalizers
const getJobsTotals = state => {
  return {...state, jobs:{...state.jobs, pending:true, error:null}};
};
const getJobsTotalsSuccess = (state, { data }) => {
  return {...state, jobs:{...state.jobs, data, pending:false, error:null}};
};
const getJobsTotalsFailure = (state, { error }) => {
  return {...state, jobs:{...state.jobs, pending: false, error} };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.FETCH_CURRICULUMS_TOTALS]: getCurriculumsTotals,
  [Types.FETCH_CURRICULUMS_TOTALS_SUCCESS]: getCurriculumsTotalsSuccess,
  [Types.FETCH_CURRICULUMS_TOTALS_FAILURE]: getCurriculumsTotalsFailure,
  [Types.FETCH_JOBS_TOTALS]: getJobsTotals,
  [Types.FETCH_JOBS_TOTALS_SUCCESS]: getJobsTotalsSuccess,
  [Types.FETCH_JOBS_TOTALS_FAILURE]: getJobsTotalsFailure
});