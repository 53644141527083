import React from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { SubTitle } from "pages/components/styles";
import ButtonFit from "pages/components/ButtonFit";

// Actions
import { Creators as CreatorsSignin } from 'store/ducks/signin';

export const Index = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignout = () => dispatch(CreatorsSignin.signout());

  return (
    <div>
      <SubTitle>Configurações da conta</SubTitle>
      <ButtonFit outlined onClick={() => navigate('/conta')} iconName="home" text="Dados cadastrais"/>
      <hr />
      <SubTitle>Vagas de emprego</SubTitle>
      <ButtonFit outlined onClick={() => navigate('/conta/curriculo')} iconName="pages" text="Meu currículo"/>
      <ButtonFit outlined onClick={() => navigate('/conta/vagas')} iconName="list" text="Vagas cadastradas"/>
      <ButtonFit outlined onClick={() => navigate('/conta/vaga/nova')} iconName="folderSpecial" text="Publicar nova vaga"/>
      <hr />
      <ButtonFit outlined onClick={handleSignout} iconName="close" text="SAIR DA CONTA"/>
    </div>
  );
}

export default Index
