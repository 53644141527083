import React from "react";
import moment from 'moment-timezone';

import { Link, Grid, makeStyles, createTheme } from "@material-ui/core";
import { Title, Text } from "pages/components/styles";
import {
  FooterContent,
  FooterBotton,
  Row,
  Column,
  LogoWhite,
  ProfileIcon,
  CustomLink,
} from "./styles";
import { Facebook, Instagram, LinkedIn, YouTube } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  icons:{
    color: theme.palette.common.white
  },
  footerLink:{
    color: '#FFFFFF'
  },
  iconsContent:{
    justifyContent: 'flex-end',
    display: 'flex',
    alignSelf: 'flex-end',
    whiteSpace: 'break-spaces',
    letterSpacing: '20px'
  }
}));

const Index = () => {
  const classes = useStyles();

  return (
    <>
      <FooterContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <LogoWhite />
            <div style={{paddingTop:'15px'}}>
              <Text color={'#FFFFFF'}>
                É a principal plataforma que conecta profissionais para espaços de saúde e bem-estar de uma forma prática e inovadora
              </Text>
            </div>
          </Grid>
          <Grid item xs={12} md={3} />
          <Grid item xs={12} md={3} style={{ display:'grid' }}>
            <Title color="#FFFFFF">Sobre nós</Title>
            <CustomLink style={{color:'#FFFFFF'}} href="https://conectclinic.com/about" target="_blank"><Text color="#FFFFFF">Sobre nós</Text></CustomLink>
            <CustomLink href="https://conectclinic.com/contact" target="_blank"><Text color="#FFFFFF">Entrar em contato</Text></CustomLink>
            <CustomLink href="https://conectclinic.com/faq" target="_blank"><Text color="#FFFFFF">Dúvidas e respostas</Text></CustomLink>
          </Grid>
          <Grid item xs={12} md={3} className={classes.iconsContent}>
            <CustomLink href="https://www.instagram.com/conect_clinic/" target="_blank"><Instagram className={classes.icons} /> </CustomLink>
            <CustomLink href="https://www.linkedin.com/company/conectclinic/" target="_blank"><LinkedIn className={classes.icons} /> </CustomLink>
            <CustomLink href="https://www.facebook.com/conectclinic/" target="_blank"><Facebook className={classes.icons} /> </CustomLink>
            <CustomLink href="https://www.youtube.com/c/ConectClinic" target="_blank"><YouTube className={classes.icons} /> </CustomLink>
          </Grid>
        </Grid>
        <FooterBotton>
          <hr />
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              {moment().format('YYYY')} - Todos os direitos reservados - versão 1.0.1
            </Grid>
            <Grid item xs={12} md={4}></Grid>
            <Grid item xs={12} md={4} style={{ display:'flex', justifyContent:'flex-end' }}>
              <CustomLink href="https://conectclinic.com/privacy" target="_blank"><Text color="#FFFFFF">Privacidade</Text></CustomLink>
              <div style={{width:'20px', height:'100%'}}/>
              <CustomLink href="https://conectclinic.com/terms" target="_blank"><Text color="#FFFFFF">Termos</Text></CustomLink>
            </Grid>
          </Grid>
        </FooterBotton>
      </FooterContent>
    </>
  );
};

export default Index;
