import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  sendEmail: ['email'],
  sendEmailSuccess: ['data'],
  sendEmailFailure: ['error'],
  checkEmailCode: ['email', 'code'],
  checkEmailCodeSuccess: ['data'],
  checkEmailCodeFailure: ['error'],
  sendCellphone: ['cellphone'],
  sendCellphoneSuccess: ['data'],
  sendCellphoneFailure: ['error'],
  checkCellphoneCode: ['cellphone', 'code'],
  checkCellphoneCodeSuccess: ['data'],
  checkCellphoneCodeFailure: ['error'],
  putPassword: ['userName', 'password'],
  putPasswordSuccess: ['data'],
  putPasswordFailure: ['error'],
  resetValidateState: []
})

/**
 * Handlers
 */
const STATE = {
  pending: false,
  data: null,
  error: null,
  checked: false,
  updated: false
}
export const INITIAL_STATE = STATE;

// Send Mail
const sendEmail = (state, {email}) => {
  return {...state, pending: true, data: null, error: null}
};
const sendEmailSuccess = (state, { data }) => {
  return {...state, data, pending:false, error:false}
};
const sendEmailFailure = (state, { error }) => {
  return {...state, pending: false, error }
};
// Check Mail Code
const checkEmailCode = (state, {email, code}) => {
  return {...state, pending: true, checked:false, error: null}
};
const checkEmailCodeSuccess = (state, { data }) => {
  return {...state, pending:false, checked:true, error:false}
};
const checkEmailCodeFailure = (state, { error }) => {
  return {...state, pending: false, error }
};

// Send Cellphone
const sendCellphone = (state, {cellphone}) => {
  return {...state, pending: true, data: null, error: null}
};
const sendCellphoneSuccess = (state, { data }) => {
  return {...state, data, pending:false, error:false}
};
const sendCellphoneFailure = (state, { error }) => {
  return {...state, pending: false, error }
};
// Check Cellphone Code
const checkCellphoneCode = (state, {cellphone, code}) => {
  return {...state, pending: true, checked:false, data: null, error: null}
};
const checkCellphoneCodeSuccess = (state, { data }) => {
  return {...state, data, pending:false, checked:true, error:false}
};
const checkCellphoneCodeFailure = (state, { error }) => {
  return {...state, pending: false, error }
};


// Update Password
const updatePassword = (state, {userName, password}) => {
  return {...state, pending: true, updated:false, data: null, error: null}
};
const updatePasswordSuccess = (state, { data }) => {
  return {...state, data, pending:false, updated:true, error:false}
};
const updatePasswordFailure = (state, { error }) => {
  return {...state, pending: false, error }
};

//Reset Validade State
const resetValidateState = state => {
  return STATE
}

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.SEND_EMAIL]: sendEmail,
  [Types.SEND_EMAIL_SUCCESS]: sendEmailSuccess,
  [Types.SEND_EMAIL_FAILURE]: sendEmailFailure,
  [Types.CHECK_EMAIL_CODE]: checkEmailCode,
  [Types.CHECK_EMAIL_CODE_SUCCESS]: checkEmailCodeSuccess,
  [Types.CHECK_EMAIL_CODE_FAILURE]: checkEmailCodeFailure,

  [Types.SEND_CELLPHONE]: sendCellphone,
  [Types.SEND_CELLPHONE_SUCCESS]: sendCellphoneSuccess,
  [Types.SEND_CELLPHONE_FAILURE]: sendCellphoneFailure,
  [Types.CHECK_CELLPHONE_CODE]: checkCellphoneCode,
  [Types.CHECK_CELLPHONE_CODE_SUCCESS]: checkCellphoneCodeSuccess,
  [Types.CHECK_CELLPHONE_CODE_FAILURE]: checkCellphoneCodeFailure,

  [Types.PUT_PASSWORD]: updatePassword,
  [Types.PUT_PASSWORD_SUCCESS]: updatePasswordSuccess,
  [Types.PUT_PASSWORD_FAILURE]: updatePasswordFailure,
  
  [Types.RESET_VALIDATE_STATE]: resetValidateState,

})
