import { createActions, createReducer } from "reduxsauce";
import { removeUser, saveUser } from 'services/auth';

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  fetchSignin: ['email', 'password'],
  fetchSigninConectClinic: ['email', 'password'],
  fetchSigninSuccess: ['user'],
  fetchSigninFailure: ['error'],
  setSigninUser: ['user'],
  signout: [],
  signinReset: []
});

/**
 * Handlers
 */
const STATE = {
  pending: false,
  user: null,
  error: null,
  expired: false
}
const INITIAL_STATE = STATE;

// Signin
const signinConectClinic = state => {
  return {...state, pending: true, user: null,error: null,expired: false};
};
// Signin
const signin = state => {
  return {...state, pending: true, user: null,error: null,expired: false};
};

const signinSuccess = (state, { user }) => {
  saveUser(user);// localStorage
  return {...state, user, pending: false, error: false};
};

const signinFailure = (state, { error }) => {
  return {...state, pending: false, error };
};

const setSigninUser = (state, { user }) => {
  return {...state, user}
}

const signout = (state, { expired = false }) => {
  removeUser(); // localStorage
  return {user:null, expired };
};

const signinReset = state => INITIAL_STATE;

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.FETCH_SIGNIN_CONECT_CLINIC]: signinConectClinic,
  [Types.FETCH_SIGNIN]: signin,
  [Types.FETCH_SIGNIN_SUCCESS]: signinSuccess,
  [Types.FETCH_SIGNIN_FAILURE]: signinFailure,
  [Types.SET_SIGNIN_USER]: setSigninUser,
  [Types.SIGNOUT]: signout,
  [Types.SIGNIN_RESET]: signinReset
});