import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import promiseMiddleware from 'redux-promise-middleware';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import reducers from './ducks';
import sagas from './sagas';

const configureStore = (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [thunk, promiseMiddleware, sagaMiddleware];
  const enhancer = composeWithDevTools(applyMiddleware(...middlewares));

  const store = createStore(reducers, initialState, enhancer);

  sagaMiddleware.run(sagas);

  return store;
};

export default configureStore;
