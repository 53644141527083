import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogQuest = (props) => {
  const {title, message, onConfirm, confirmLabel, onClose, opened, fullScreen} = props;

  return (
    <div>
      <Dialog fullScreen={fullScreen}
        open={opened}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {message
            ? <DialogContentText id="alert-dialog-description">
                {message}
              </DialogContentText>
            : null
          }
          {props.children}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="default">
            Cancelar
          </Button>
          <Button onClick={onConfirm} color="primary" autoFocus>
            {confirmLabel ? confirmLabel : 'CONFIRMAR'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DialogQuest