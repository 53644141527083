import Avatar from '@material-ui/core/Avatar';
import Logo from 'assets/icons/logo-icon.png';
import PropTypes from 'prop-types';
import * as React from 'react';


function Logomarca(props) {
  const {small} = props;
  return (
    <Avatar variant="square" src={Logo} />
  );
}

Logomarca.propTypes = {
  children: PropTypes.node,
};

export default Logomarca;
