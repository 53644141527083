import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom";

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';

import MenuIcon from '@material-ui/icons/Menu';
import Dashboard from '@material-ui/icons/Dashboard';
import PeopleAltRounded from '@material-ui/icons/PeopleAltRounded';
import SecurityRounded from '@material-ui/icons/SecurityRounded';
import PagesRounded from '@material-ui/icons/PagesRounded';
import FolderOpenRounded from '@material-ui/icons/FolderOpenRounded';
import ExitToAppRounded from '@material-ui/icons/ExitToAppRounded';


import {LogoWhite} from "pages/components/styles";
import Footer from "pages/components/Footer";
import backgroundGreenGradient from "assets/background-green-gradient.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    backgroundImage: `url(${backgroundGreenGradient})`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    ...theme.mixins.toolbar,
    backgroundImage: `url(${backgroundGreenGradient})`,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: '500px',
  },
  customButton: {
    backgroundColor: '#1E50A0',
    borderRadius: '8px',
    color: '#FFFFFF',
    fontSize: '0.7rem',
    height: '70%',
    paddingTop: '10px',
    paddingBottom: '10px',
    position: 'relative',
    bottom: '8px'
  },
  textField: {
    borderColor:'#FFFFFF',
    border: '0px',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
  },
  form: {
    display: 'contents'
  }
}));

function Index(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <ListItem button onClick={()=>navigate("/home")}>
          <ListItemIcon> <Dashboard /> </ListItemIcon>
          <ListItemText primary={"Dashboard"} />
        </ListItem>
        <ListItem button onClick={()=>navigate("/usuarios")}>
          <ListItemIcon> <PeopleAltRounded /> </ListItemIcon>
          <ListItemText primary={"Lista de usuários"} />
        </ListItem>
        <ListItem button onClick={()=>navigate("/curriculos")}>
          <ListItemIcon> <PagesRounded /> </ListItemIcon>
          <ListItemText primary={"Lista de Currículos"} />
        </ListItem>
        <ListItem button onClick={()=>navigate("/vagas")}>
          <ListItemIcon> <FolderOpenRounded /> </ListItemIcon>
          <ListItemText primary={"Lista de Vagas"} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button onClick={()=>navigate("/access")}>
          <ListItemIcon> <SecurityRounded /> </ListItemIcon>
          <ListItemText primary={"Acessos"} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button onClick={()=>navigate("/signout")}>
          <ListItemIcon> <ExitToAppRounded /> </ListItemIcon>
          <ListItemText primary={"Desconectar"} />
        </ListItem>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <LogoWhite />
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <div>
        <main className={classes.content}>
          <div style={{height:'35px'}} />
          {props.children}
        </main>
        <Footer />
      </div>
    </div>
  );
}

Index.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Index;
