import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { TextField, Grid, LinearProgress } from "@material-ui/core";
import { ChevronRightSharp } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from "@material-ui/core/Checkbox";

import { Title, Text, Line, CustomLink } from "pages/components/styles";
import Header from "pages/components/Header";
import Footer from "pages/components/Footer";
import Button from "pages/components/Button";
import { Card } from "pages/components/styles";
import SigninSymbol from "assets/icons/signin-symbol.png";

import { MainContent } from "./styles";

import { getUser } from 'services/auth';
// Actions
import { Creators } from 'store/ducks/signup';

const useStyles = makeStyles((theme) => ({
  customButton: {
    backgroundColor: '#1E50A0',
    borderRadius: '8px',
    color: '#FFFFFF',
    fontSize: '0.7rem',
    height: '70%',
    paddingTop: '10px',
    paddingBottom: '10px',
    position: 'relative',
    bottom: '8px',
    alignSelf: 'flex-end'
  },
  textField: {
    borderColor:'#FFFFFF',
    border: '0px',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    marginRight: '5px',
  },
  form: {
    display: 'contents'
  },
  checkedLabel: {
    fontSize:'5px'
  }
}));

function Index(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pending, data, error } = useSelector(state => state.signup);

  const [userLogged, setUserLogged] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [terms, setTerms] = useState(false);
  const [accept, setAccept] = useState(false);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    const _user = getUser();
    setUserLogged(_user);
    if(!_user || !_user.hash) {
      navigate('/login');
    }
  },[]);

  useEffect(() => {
    if(data && data.id) {
      navigate('/cadastro/sucesso');
    }
  },[data]);


  const handleSubmit = (e) => {
    e.preventDefault();

    setIsValid(password === rePassword);

    if(password !== rePassword){
      return;
    }

    const USER = { name, email, password, type: 1};
    dispatch(Creators.postSignup(USER));
  }

  return <div>
    <Header />

    <MainContent bgColor={'#FBFBFB'}>
      <Line>
        <Text flex> <CustomLink href="/">Home</CustomLink> <ChevronRightSharp /> Nova conta</Text>
      </Line>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            {pending && <LinearProgress />}
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Title bigger center>Criar Conta</Title>
                </Grid>
              </Grid>
              {!isValid && 
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Title color="#7d0408" center>As senhas digitadas não são iguais!</Title>
                  </Grid>
                </Grid>
              }
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <TextField disabled={pending} variant="outlined" fullWidth
                    value={name} required
                    onChange={e => setName(e.target.value)} 
                    placeholder="Nome completo"
                    className={classes.textField}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <TextField disabled={pending} variant="outlined" fullWidth
                    value={email} required
                    type="email"
                    onChange={e => setEmail(e.target.value)} 
                    placeholder="Endereço de e-mail"
                    className={classes.textField}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <TextField disabled={pending} variant="outlined" fullWidth
                    value={password} required
                    type="password" autoComplete="off"
                    onChange={e => setPassword(e.target.value)} 
                    placeholder="Senha"
                    className={classes.textField}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <TextField disabled={pending} variant="outlined" fullWidth
                    value={rePassword} required
                    type="password" autoComplete="off"
                    onChange={e => setRePassword(e.target.value)} 
                    placeholder="Confirme a senha"
                    className={classes.textField}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Checkbox required checked={terms} onChange={e => setTerms(e.target.checked)} />
                  <Text medium>Eu aceito os <CustomLink href="https://conectclinic.com/terms" target="_blank">termos e condições</CustomLink>, e confirmo que li e entendi a <CustomLink href="https://conectclinic.com/privacy" target="_blank">política de privacidade</CustomLink>.</Text>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Checkbox required  checked={accept} onChange={e => setAccept(e.target.checked)} />
                  <Text medium>Declaro estar ciente das normas profissionais que deve seguir e da necessidade de  inclusão do(s)s número de registro profissional e de especialidade (quando cabível).*</Text>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Button disabled={pending}
                    fullWidth
                    type="submit"
                    bgColor="#3FB992"
                    text={'Continuar'}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Text medium>Ao preencher seus dados e criar sua conta na plataforma Conect Clinic, você concorda com os termos e condições e afirma estar ciente das políticas de privacidade.</Text>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} style={{textAlign:'center', alignSelf:'center', paddingTop:'40px', paddingBottom:'40px'}}>
                  <Text>Já possui conta.? <CustomLink href="/login">ENTRAR</CustomLink></Text>
                </Grid>
              </Grid>
            </form>
            {pending && <LinearProgress />}
          </Card>
        </Grid>

        <Grid item xs={12} md={6} style={{textAlign:'center', alignSelf:'center'}}>
          <img src={SigninSymbol} style={{width: '100%', maxWidth:'310px', maxHeight:'290px'}} />
        </Grid>
      </Grid>

    </MainContent>

    <Footer />
  </div>;
}

export default Index;
