import React from 'react';
import { Title, Text, Line } from "pages/components/styles";
import { 
  HomeSharp, Forward, FolderSharp, 
  PersonOutlineRounded, PagesRounded, 
  ListAltRounded, FolderSpecialRounded,
  CloseRounded
} from "@material-ui/icons";
import { Container } from  "./styles";

const Index = (props) => {
  const { text, iconName, outlined } = props;

  return (
    <Container {...props}>
      {iconName==='home' && <HomeSharp style={{flex:0.1, color: outlined ? '#15363F' : '#FFFFFF'}} />}
      {iconName==='folder' && <FolderSharp style={{flex:0.1, color: outlined ? '#15363F' : '#FFFFFF'}} />}
      {iconName==='folderSpecial' && <FolderSpecialRounded style={{flex:0.1, color: outlined ? '#15363F' : '#FFFFFF'}} />}
      {iconName==='person' && <PersonOutlineRounded style={{flex:0.1, color: outlined ? '#15363F' : '#FFFFFF'}} />}
      {iconName==='pages' && <PagesRounded style={{flex:0.1, color: outlined ? '#15363F' : '#FFFFFF'}} />}
      {iconName==='list' && <ListAltRounded style={{flex:0.1, color: outlined ? '#15363F' : '#FFFFFF'}} />}
      {iconName==='close' && <CloseRounded style={{flex:0.1, color: outlined ? '#15363F' : '#FFFFFF'}} />}
      <Text color={outlined ? '#15363F' : '#FFFFFF'} style={{flex:0.8}}>{text}</Text>
      <Forward style={{flex:0.1, color: outlined ? '#15363F' : '#FFFFFF'}} />
    </Container>
  );
}

export default Index
