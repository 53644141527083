import React from 'react';
import { Grid } from '@material-ui/core';

import Button from "pages/components/Button";
import { Text, Title } from "pages/components/styles";
import { Container } from  "./styles";
import { IconHeartGray, IconPinGray, IconFolderGray } from "pages/components/custom-icons";

const Index = (props) => {
  const { title, company, local, description, date, hiringType, wage } = props;

  return (
    <Container {...props}>
      <Grid container spacing={3}>
        <Grid item xs={10} md={10}>
          <Text big bold color={'#3FB992'} style={{flex:0.8, display:'contents'}}>{title}</Text>
        </Grid>
        <Grid item xs={2} md={2} style={{ display:'flex', justifyContent:'flex-end' }}>
          {/* <ButtonIcon text="Salvar" /> */}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Text color={'#15363F'}><IconFolderGray /> {company}</Text>
        </Grid>
        <Grid item xs={12} md={6}>
          <Text color={'#15363F'}><IconPinGray /> {local}</Text>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Text color={'#9F9E9E'}>{description}</Text>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Text color={'#9F9E9E'}>{date}</Text>
        </Grid>
        <Grid item xs={12} md={9}>
          <Text bold color={'#3FB992'}>{`${hiringType} | ${wage}`}</Text>
        </Grid>
      </Grid>
      {props.onClick && <Title medium center>clique e saiba mais</Title> }
    </Container>
  );
}

export default Index
