export const onlyDigits = text => {
  return text.replace(/[^\d]+/g, '');
};

export const isValidMail = (email) => {
  return /\S+@\S+\.\S+/.test(email);
}

export const isValidCellphone = (phone) => {
  const regex = /^\(?([0-9]{2})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/;
  return regex.test(phone);
}

export const validateCPF = cpf => {
  const document = onlyDigits(cpf);
  const firstVerifyingDigit = parseInt(document.substr(9, 1), 10);
  const lastVerifyingDigit = parseInt(document.substr(10, 1), 10);

  if (
    document === '11111111111' ||
    document === '22222222222' ||
    document === '33333333333' ||
    document === '44444444444' ||
    document === '55555555555' ||
    document === '66666666666' ||
    document === '77777777777' ||
    document === '88888888888' ||
    document === '99999999999' ||
    document === '00000000000'
  ) {
    return false;
  }

  const calcRange = (length, multiplier) => {
    let m = multiplier;

    const rangeMultiplied = document
      .substr(0, length)
      .split('')
      .map(n => {
        const calc = parseInt(n, 10) * m;
        m--;
        return calc;
      });

    // Sim
    const rangeSum = rangeMultiplied.reduce((sum, current) => sum + current);

    return rangeSum;
  };

  // Check first verifying digit
  let restFirst = parseInt(calcRange(9, 10) * 10, 10) % 11;
  restFirst = restFirst === 10 || restFirst === 11 ? 0 : restFirst;

  if (restFirst === firstVerifyingDigit) {
    // Check last verifying digit
    let restLast = parseInt(calcRange(10, 11) * 10, 10) % 11;
    restLast = restLast === 10 || restLast === 11 ? 0 : restLast;

    if (restLast === lastVerifyingDigit) {
      return true;
    }
  }

  return false;
};

export const slug = (str, whiteSpaces = '-') => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
  var to = 'aaaaaaeeeeiiiioooouuuunc------';

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, whiteSpaces) // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes
    .replace(/^-+/, '') // trim - from start of text
    .replace(/-+$/, ''); // trim - from end of text

  return str;
};


export const geolocationDiff = (lat1, lon1, lat2, lon2, unit) => {
  var radlat1 = Math.PI * lat1/180
  var radlat2 = Math.PI * lat2/180
  var theta = lon1-lon2
  var radtheta = Math.PI * theta/180
  var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist)
  dist = dist * 180/Math.PI
  dist = dist * 60 * 1.1515
  if (unit=="K") { dist = dist * 1.609344 }
  if (unit=="N") { dist = dist * 0.8684 }
  return dist
}
