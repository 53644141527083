import styled from "styled-components";

export const Container = styled.button`
  width: ${props => props.width ? props.width : '100%'};
  background: ${ props => props.outlined ? 'transparent' : props.bgColor ? props.bgColor : '#3fb992' };
  border: 1px solid ${ props => props.borderColor ? props.borderColor : 'transparent'};
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
  ${props => props.marginLeft ? {marginLeft: props.marginLeft}  : {} };
  ${props => props.marginRight ? {marginRight: props.marginRight}  : {} };
  &:hover{
    background: ${ props => props.outlined ? `${props.borderColor}90` : props.bgColor ? `${props.bgColor}90` : '#3fb99290'};
  }
  &:active{
    background: ${ props => props.outlined ? `${props.borderColor}50` : props.bgColor ? `${props.bgColor}` : '#3fb992'};
  }
`;
