import React from 'react';
import { CircularProgress } from "@material-ui/core";
import { Text } from "pages/components/styles";
import { Container } from  "./styles";

const Index = (props) => {
  const { text, pending } = props;

  return (
    <Container {...props} disabled={pending}>
      {pending
        ? <CircularProgress size="1.4rem" style={{color:'white'}} />
        : <Text color={props.outlined ? 'gray' : '#FFFFFF'} style={{flex:0.8, display:'contents'}}>{text}</Text>
      }
    </Container>
  );
}

export default Index
