import React from "react";
import { Grid } from "@material-ui/core";
import { CalendarTodayOutlined, FolderOutlined, PinDropSharp } from "@material-ui/icons";
import moment from "moment-timezone";

import { Title, Text } from "pages/components/styles";

import HIRING_TYPES from "assets/data/hiring-types.json";
import MODALITIES from "assets/data/modalities.json";

export const Index = (props) => {
  const { job } = props;

  const getTypeHiring = (id) => HIRING_TYPES.find(v => v.id === String(id))?.label;
  const getModality = (id) => MODALITIES.find(v => v.id === String(id))?.label;

  return (
    <div>
      <Title bigger>{job?.job_title}</Title>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3} style={{display:"flex", alignItems:"center"}}>
          <FolderOutlined style={{color:"#3FB992"}} /> <Text big bold>{job?.company}</Text>
        </Grid>
        <Grid item xs={12} md={3} style={{display:"flex", alignItems:"center"}}>
          <PinDropSharp style={{color:"#3FB992"}} /> {job?.city?.name} - {job?.city?.uf}
        </Grid>
        <Grid item xs={12} md={3} style={{display:"flex", alignItems:"center"}}>
          <CalendarTodayOutlined style={{color:"#3FB992"}} /> {moment(job?.job_expiration).format("DD/MM/YYYY")}
        </Grid>
      </Grid>
      <div style={{height:'30px'}} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Text style={{ border:'1px solid #3FB992', padding:'5px', borderRadius:'20px', paddingLeft:'20px' ,paddingRight:'20px', marginRight:'10px'}}>
                {getTypeHiring(job?.job_tphiring)}
              </Text>
            </Grid>
            <Grid item xs={12} md={3}>
              <Text style={{ border:'1px solid #3FB992', padding:'5px', borderRadius:'20px', paddingLeft:'20px' ,paddingRight:'20px'}}>
                {getModality(job?.job_modality || 1)}
              </Text>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          <Title big>Descrição</Title>
          <Text>{job?.job_description}</Text>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          <Title big>Requerimentos</Title>
          <Text><pre>{job?.job_requirements}</pre></Text>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          <Title big>Salário</Title>
          <Text>{job?.job_salary || "A Combinar"}</Text>
        </Grid>
      </Grid>
    </div>
  );
}

export default Index
