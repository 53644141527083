import axios from 'axios';
import { getUser } from './auth';
import { BASE_URL } from './constants';

console.log(BASE_URL);

const api = axios.create({
  baseURL: BASE_URL,
  timeout: 300000,
});

api.interceptors.request.use(async config => {
  const user = await getUser();
  const cnfg = { ...config };

  // cnfg.headers['cache-control'] = 'no-cache';
  // cnfg.headers['Content-Type'] = 'application/json';
  
  if(user){
    cnfg.headers.Authorization = `Bearer ${user.hash}`;
  }

  return cnfg;
});

api.interceptors.response.use(
  response => {
    if (response.data.statusCode === 200 || response.data.statusCode === 201) {
      return response;
    } else {
      const { data } = response;
      const message = data.body ? data.body.Message : data.message;

      return Promise.reject({ response, message });
    }
  },
  error => {
    console.log('error:', error);
    return Promise.reject(error);
  },
);

export default api;
