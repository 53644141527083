import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@material-ui/core";

import { Title } from "pages/components/styles";
import Header from "pages/components/Header";
import Footer from "pages/components/Footer";
import Button from "pages/components/Button";
import { Card } from "pages/components/styles";
import SigninSymbol from "assets/icons/signin-symbol.png";

import { MainContent } from "./styles";

function Index(props) {
  const navigate = useNavigate();

  return <div>
    <Header />

    <MainContent bgColor={'#FBFBFB'}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Title bigger center>Nova senha criada com sucesso!</Title>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Title center>Clique no botão abaixo para fazer o login</Title>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Button fullWidth bgColor="#3FB992"
                    text={'Ir para Login'}
                    onClick={() => navigate("/login")}
                  />
                </Grid>
              </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} style={{textAlign:'center', alignSelf:'center'}}>
          <img src={SigninSymbol} style={{width: '100%', maxWidth:'310px', maxHeight:'290px'}} />
        </Grid>
      </Grid>

    </MainContent>

    <Footer />
  </div>;
}

export default Index;
