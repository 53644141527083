
export const getUser = () => {
  try {
    let user = localStorage.getItem('@USER_LOGGED');
    if (user != null) {
      user = JSON.parse(user);
      return user;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};

export const saveUser = user => {
  return localStorage.setItem('@USER_LOGGED', JSON.stringify(user));
};

export const removeUser = () => localStorage.removeItem('@USER_LOGGED');
