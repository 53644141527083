import { call, put } from 'redux-saga/effects';
import api from 'services/api';
import { Creators } from 'store/ducks/curriculums';

export function* postCurriculum({ data }) {
  try {
    const response = yield call(api.post, '/v1/curriculums', data);
    const { body } = response.data;

    yield put(Creators.postCurriculumSuccess(body));
  } catch (e) {
    const { response } = e;
    yield put(
      Creators.setCurriculumsFailure(response.data)
    );
  }
}

export function* getCurriculums({ count }) {
  try {
    const response = yield call(api.get, `/v1/public/curriculums?last=${count}`);
    const { body } = response.data;

    yield put(Creators.setCurriculumsSuccess(body));
  } catch (e) {
    const { response } = e;
    yield put(
      Creators.setCurriculumsFailure(response.data)
    );
  }
}

export function* getCurriculumByUserId({ userId }) {
  try {
    const response = yield call(api.get, `/v1/curriculums?userId=${userId}`);
    const { body } = response.data;

    yield put(Creators.setCurriculumsSuccess(body));
  } catch (e) {
    const { response } = e;
    yield put(
      Creators.setCurriculumsFailure(response.data)
    );
  }
}

export function* getCurriculumByUserName({ userName }) {
  try {
    const response = yield call(api.get, `/v1/curriculums?name=${userName}`);
    const { body } = response.data;

    yield put(Creators.setCurriculumsSuccess(body));
  } catch (e) {
    const { response } = e;
    yield put(
      Creators.setCurriculumsFailure(response.data)
    );
  }
}

export function* getCurriculumsByPeriod({ dateFrom, dateTo }) {
  try {
    const response = yield call(api.get, `/v1/curriculums?dateFrom=${dateFrom}&dateTo=${dateTo}`);
    const { body } = response.data;

    yield put(Creators.setCurriculumsSuccess(body));
  } catch (e) {
    const { response } = e;
    yield put(
      Creators.setCurriculumsFailure(response.data)
    );
  }
}
