import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  fetchCitiesByName: ['name'],
  fetchCitiesByNameSuccess: ['data'],
  fetchCitiesByNameFailure: ['error']
});

/**
 * Handlers
 */
const STATE = {
  pending: false,
  data: [],
  error: null
}
const INITIAL_STATE = STATE;

// Cities
const getCitiesByName = state => {
  return {...state, pending:true, error:null};
};

const getCitiesByNameSuccess = (state, { data }) => {
  return {...state, data, pending:false, error:false};
};

const getCitiesByNameFailure = (state, { error }) => {
  return {...state, pending: false, error };
};


/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.FETCH_CITIES_BY_NAME]: getCitiesByName,
  [Types.FETCH_CITIES_BY_NAME_SUCCESS]: getCitiesByNameSuccess,
  [Types.FETCH_CITIES_BY_NAME_FAILURE]: getCitiesByNameFailure
});