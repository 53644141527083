import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  postCurriculum: ['data'],
  postCurriculumSuccess: ['data'],
  fetchCurriculums: ['count'],
  fetchCurriculumsByUserId: ['userId'],
  fetchCurriculumsByUserName: ['userName'],
  fetchCurriculumsByPeriod: ['dateFrom', 'dateTo'],
  setCurriculumsSuccess: ['data'],
  setCurriculumsFailure: ['error']
});

/**
 * Handlers
 */
const STATE = {
  pending: false,
  updated: false,
  data: [],
  error: null
}
const INITIAL_STATE = STATE;

// Curriculum
const insertCurriculum = (state, { data }) => {
  return {...state, pending:true, updated:false, error:null};
};

const insertCurriculumSuccess = (state, { data }) => {
  return {...state, pending:false, updated:true, error:false};
};

const getCurriculums = state => {
  return {...state, pending:true, error:null};
};

const getCurriculumsByUserId = state => {
  return {...state, data:[], pending:true, error:null};
};

const getCurriculumsByUserName = state => {
  return {...state, data:[], pending:true, error:null};
};

const getCurriculumsByPeriod = state => {
  return {...state, data:[], pending:true, error:null};
};

const setCurriculumsSuccess = (state, { data }) => {
  return {...state, data, pending:false, error:false};
};

const setCurriculumsFailure = (state, { error }) => {
  return {...state, pending: false, updated:false, error };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.POST_CURRICULUM]: insertCurriculum,
  [Types.POST_CURRICULUM_SUCCESS]: insertCurriculumSuccess,
  [Types.FETCH_CURRICULUMS]: getCurriculums,
  [Types.FETCH_CURRICULUMS_BY_USER_ID]: getCurriculumsByUserId,
  [Types.FETCH_CURRICULUMS_BY_USER_NAME]: getCurriculumsByUserName,
  [Types.FETCH_CURRICULUMS_BY_PERIOD]: getCurriculumsByPeriod,
  [Types.SET_CURRICULUMS_SUCCESS]: setCurriculumsSuccess,
  [Types.SET_CURRICULUMS_FAILURE]: setCurriculumsFailure
});