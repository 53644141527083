import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  uploadFile: ['file'],
  uploadFileSuccess: ['data'],
  uploadFileFailure: ['error']
});

/**
 * Handlers
 */
const STATE = {
  pending: false,
  data: null,
  error: null
}
const INITIAL_STATE = STATE;

// uploadFile
const uploadFile = state => {
  return {...state, pending:true, data:null, error:null};
};

const uploadFileSuccess = (state, { data }) => {
  return {...state, data, pending:false, error:false};
};

const uploadFileFailure = (state, { error }) => {
  return {...state, pending:false, error };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.UPLOAD_FILE]: uploadFile,
  [Types.UPLOAD_FILE_SUCCESS]: uploadFileSuccess,
  [Types.UPLOAD_FILE_FAILURE]: uploadFileFailure
});