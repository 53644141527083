import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import { Grid, LinearProgress, TextField } from "@material-ui/core";
import Header from "pages/components/Header";
import Footer from "pages/components/Footer";
import Button from "pages/components/Button";
import { Card } from "pages/components/styles";

import Logomarca from 'pages/components/logo';
import { Title, Text, CustomLink } from "pages/components/styles";
import SigninSymbol from "assets/icons/signin-symbol.png";

import { MainContent } from "./styles";
import { isValidCellphone, isValidMail } from 'services/helpers';

// Actions
import { Creators } from 'store/ducks/validate';

export const Index = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pending, error, data } = useSelector(state => state.validate);
  const [email, setEmail] = useState('');
  const [sendedSuccess, setSendedSuccess] = useState(false);

  useEffect(() => {
    if(data) {
      const {email, sendMailResponse} = data;
      const isSended = email && sendMailResponse?.MessageId;
      setSendedSuccess(isSended);
    }
  },[data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if(isValidMail(email)){
      dispatch(Creators.sendEmail(email));
    }
  };

  return (
    <div>
      <Header />
      <MainContent bgColor={'#FBFBFB'}>
        <Grid container spacing={3} style={{width: 'calc(100% - 24px)'}}>
          <Grid item xs={12} md={6}>
            <Card toFit>
              {pending && <LinearProgress />}
              <div style={{justifyContent:'center', display:'flex'}}>
                <Logomarca small />
              </div>
              <Title big center color="#3FB992">
                Recuperação de Senha
              </Title>
              <Text medium color="#15363F">Informe o e-mail que você usou no momento do cadastro</Text>

              <form onSubmit={handleSubmit}>
                <TextField disabled={pending} variant="outlined" margin="normal" required fullWidth
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  label="E-mail"
                  autoFocus
                />
                <div style={{width:'100%', height:'30px'}} />
                {sendedSuccess &&
                  <>
                    <Title big center color="#3FB992">
                      E-mail enviado!
                    </Title>
                    <Title center color="#3FB992">
                      Verifique sua caixa de entrada e de spam
                    </Title>
                    <div style={{textAlign:'center'}}>
                      <Text medium color="#15363F">Clique no link enviado no e-mail para verificar seu email</Text>
                    </div>
                    <div style={{width:'100%', height:'30px'}} />
                    <div style={{textAlign:'center'}}>
                      <Text medium bold color="#15363F">Se você não receber em até 5 minutos clique em reenviar </Text>
                    </div>
                    <div style={{width:'100%', height:'30px'}} />
                  </>
                }
                <Button pending={pending} fullWidth bgColor="#3FB992"
                  type="submit"
                  text={sendedSuccess ? 'Reenviar e-mail' : 'Recuperar Senha'}
                />
                <div style={{width:'100%', height:'30px'}} />
              </form>

              <Grid container spacing={3}>
                <Grid item xs={12} md={12} style={{textAlign:'center', alignSelf:'center', paddingTop:'40px', paddingBottom:'40px'}}>
                  <Text>Já possui conta.? <CustomLink href="/login">ENTRAR</CustomLink></Text>
                </Grid>
              </Grid>
              {pending && <LinearProgress />}
            </Card>
          </Grid>
          <Grid item xs={12} md={6} style={{textAlign:'center', alignSelf:'center'}}>
            <img src={SigninSymbol} style={{width: '100%', maxWidth:'310px', maxHeight:'290px'}} />
          </Grid>
        </Grid>
        <div style={{width:'100%', height:'50px'}} />
      </MainContent>
      <Footer />
    </div>
  );
}

export default Index
