import styled from "styled-components";
import { Link, Typography, Button } from "@material-ui/core";
import backgroundGreenGradient from "assets/background-green-gradient.png";
import logoWhite from "assets/logo-white.png";
import ProfileImage from "assets/icons/icon-profile.png";

export const CustomHeader = styled.div`
  flex-direction: row;
  width: 100vw;
  height: 113px;
  display: flex;
  align-items: center;
  background-image: url(${backgroundGreenGradient});
  margin-bottom: -10px;
`;

export const CustomLink = styled(Link).attrs({
  style: {color:'#3FB992', fontWeight:'bold'}
})`
  cursor: pointer;
  ${props => props.block ? {display: 'block'} : {} }
  ${props => props.flex ? {display: 'flex'} : {} }
  ${props => props.padding ? {padding: props.padding} : {} }

`;

export const Row = styled.div`
  flex-direction: row;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const Column = styled.div`
  width: ${props => props.width};
  height: 100%;
  ${props => props.flex ? {display: 'flex'} : {}}
  align-items: center;
  ${props => props.toRight ? {justifyContent: 'flex-end'} : {}}
`;

export const LogoWhite = styled.img.attrs({
  src: `${logoWhite}`
})`
  width: 209px;
  height: 46px;
  @media(max-width: 420px) {
    width: 100%;
    height: auto;
  }
`;

export const MediaHidden = styled.span`
  @media(max-width: 420px) {
    display: none;
  }
`;

export const ProfileIcon = styled.img.attrs({
  src: `${ProfileImage}`
})`
  width: 37px;
  height: 37px;
`;

export const Title = styled.p`
  color: ${props => props.color ? props.color : '#15363F' };
  text-align: ${props => props.center ? 'center' : props.right ? 'right' : 'left' };
  font-weight: bold;
  ${props => props.block ? {display: 'block'} : {} }
  ${props => props.size 
    ? {fontSize:props.size} 
      : props.small ? {fontSize:'0.5rem'} 
      : props.medium ? {fontSize:'0.8rem'} 
      : props.big ? {fontSize:'1.5rem'} 
      : props.bigger ? {fontSize:'2rem'}
    : {fontSize:'1rem'}
  };
  ${props => props.center ? {justifyContent: 'center'} : {} }
`;

export const SubTitle = styled.p`
  color: ${props => props.color ? props.color : '#15363F' };
  text-align: ${props => props.center ? 'center' : 'left' };
  ${props => props.block ? {display: 'block'} : {} }
  ${props => props.size 
    ? {fontSize:props.size} 
      : props.small ? {fontSize:'0.5rem'} 
      : props.medium ? {fontSize:'0.8rem'} 
      : props.big ? {fontSize:'1.5rem'} 
      : props.bigger ? {fontSize:'2rem'}
    : {fontSize:'1rem'}
  };
  ${props => props.center ? {justifyContent: 'center'} : {} }
`;

export const Text = styled.span`
  color: ${props => props.color ? props.color : '#383838' };
  text-align: ${props => props.center ? 'center' : 'left' };
  ${props => props.size 
    ? {fontSize:props.size} 
      : props.small ? {fontSize:'0.5rem'} 
      : props.medium ? {fontSize:'0.8rem'} 
      : props.big ? {fontSize:'1.5rem'} 
      : props.bigger ? {fontSize:'2rem'} 
    : {fontSize:'1rem'}
  };
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  font-weight: ${props => props.bold ? 'bold' : 'normal'};
  ${props => props.block ? {display: 'block'} : {} }
  ${props => props.flex ? {display: 'flex'} : {} }
  ${props => props.center ? {justifyContent: 'center'} : {} }
`;

export const Line = styled.div`
  display: flex;
  ${props => props.right ? {justifyContent: 'flex-end'} : {}}
  ${props => props.centerContent ? {justifyContent: 'center'} : {}}
  ${props => props.top ? {paddingTop: props.top} : {}}
  ${props => props.bottom ? {paddingBottom: props.bottom} : {}}
  ${props => props.marginBottom ? {marginBottom: props.marginBottom} : {}}
  ${props => props.lineBottom ? {borderBottom: '2px solid #FBFBFB'} : {}}
`;

export const FooterContent = styled.div`
  padding: 50px;
  min-height: 200px;
  background: #15363F;
  @media(max-width: 420px) {
    padding: 10px;
  }
`;

export const FooterBotton = styled.div`
  padding: 10px;
  padding-bottom: 30px;
  bottom: 10px;
  color: #FFFFFF;
  font-size: 0.8rem;
`;

export const Card = styled.div`
  ${props => props.toFit ? {width: '100%', height: '100%'} : {} }
  background: #FFFFFF;
  border: 0px;
  border-radius: 20px;
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
`;
