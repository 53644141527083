import { takeEvery } from 'redux-saga/effects';
import { Types } from 'store/ducks/validate';
import { checkCellphoneCode, checkEmailCode, sendCellphone, sendEmail, updatePassword } from './validate';

const validateSagas = [
	takeEvery(Types.SEND_EMAIL, sendEmail),
  takeEvery(Types.SEND_EMAIL_SUCCESS, (data) => {
  }),
  takeEvery(Types.SEND_EMAIL_FAILURE, () => {
  }),
	takeEvery(Types.CHECK_EMAIL_CODE, checkEmailCode),
  takeEvery(Types.CHECK_EMAIL_CODE_SUCCESS, (data) => {
  }),
  takeEvery(Types.CHECK_EMAIL_CODE_FAILURE, () => {
  }),

  takeEvery(Types.SEND_CELLPHONE, sendCellphone),
  takeEvery(Types.SEND_CELLPHONE_SUCCESS, (data) => {
  }),
  takeEvery(Types.SEND_CELLPHONE_FAILURE, () => {
  }),
	takeEvery(Types.CHECK_CELLPHONE_CODE, checkCellphoneCode),
  takeEvery(Types.CHECK_CELLPHONE_CODE_SUCCESS, (data) => {
  }),
  takeEvery(Types.CHECK_CELLPHONE_CODE_FAILURE, () => {
  }),

	takeEvery(Types.PUT_PASSWORD, updatePassword),
  takeEvery(Types.PUT_PASSWORD_SUCCESS, (data) => {
  }),
  takeEvery(Types.PUT_PASSWORD_FAILURE, () => {
  }),
  
]

export default validateSagas