import React, { useEffect } from "react";
import { useDispatch} from 'react-redux';
import { useNavigate } from "react-router-dom";

// Actions
import { Creators as CreatorsSignin } from 'store/ducks/signin';

export const Index = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(CreatorsSignin.signout());
    navigate('/');
  },[]);

  return <></>
}

export default Index
