import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Grid, LinearProgress } from "@material-ui/core";
import { EmailRounded, LinkedIn, PhoneRounded, CakeOutlined } from "@material-ui/icons";
import moment from "moment-timezone";

import { Title, Text, CustomLink } from "pages/components/styles";

import HIRING_TYPES from "assets/data/hiring-types.json";

export const Index = (props) => {
  const { data } = props;
  // const { data } = useSelector(state => state.curriculums);

  const getHiringType = (id) => {
    const type = [...HIRING_TYPES].find(e => e.id == id);
    return type ? String(type.label).split(' ')[0] : "";
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3} />
      <Grid item xs={12} md={6}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{display:'grid'}}>
            <Text bigger bold>{data?.name}</Text>
            <Text>{data?.profession} - {getHiringType(data?.tphiring)}</Text>
            <Text flex><CakeOutlined /> {moment(data?.birth).format("DD/MM/YYYY")}</Text>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{display:'grid'}}>
            <Text bold center>P R O F I L E </Text>
            <Text>{data?.presentation}</Text>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{display:'grid'}}>
            <Text bold center>C O N T A T O S</Text>
            <Text flex><EmailRounded /> {data?.email}</Text>
            <Text flex><PhoneRounded /> {data?.telephone}</Text>
            <CustomLink href={data?.linkedin} target="_blank"><Text flex><LinkedIn /> {data?.linkedin}</Text></CustomLink>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{display:'grid'}}>
            <Text bold center>E N D E R E Ç O</Text>
            <Text flex>{data?.address_street} {data?.address_number}, {data?.address_complement}, {data?.address_neighborhood} {data?.city?.name}/{data?.city?.uf} - CEP: {data?.address_postal_code} </Text>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={3} />
    </Grid>
  );
}

export default Index
