import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { CustomHeader, LogoWhite, CustomLink } from "./styles";

const Index = () => {
  return (
    <CustomHeader>
      <Grid container spacing={3}>
        <Grid item xs={12} md={1} />
        <Grid item xs={12} md={11}>
          <CustomLink href="/">
            <LogoWhite />
          </CustomLink>
        </Grid>
      </Grid>
    </CustomHeader>
  );
};

export default Index;
