import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  fetchUser: ['id'],
  fetchUserSuccess: ['user'],
  fetchUserFailure: ['error'],
  fetchUsersConectClinic: [],
  fetchUsersByName: ['name', 'isConect'],
  fetchUsersByPeriod: ['dateFrom', 'dateTo'],
  fetchUsersSuccess: ['users'],
  fetchUsersFailure: ['error'],
  fetchUsersApp: ['id'],
  fetchUsersAppSuccess: ['users'],
  fetchUsersAppFailure: ['error'],
  postUser: ['user'],
  postUserSuccess: ['data'],
  postUserFailure: ['error'],
  putUser: ['user'],
  putUserSuccess: ['data'],
  putUserFailure: ['error'],
  putRecoverPassword: ['user'],
  putRecoverPasswordSuccess: ['data'],
  putRecoverPasswordFailure: ['error'],
  deleteUser: ['id'],
  deleteUserSuccess: ['data'],
  deleteUserFailure: ['error'],
  signout: []
})

/**
 * Handlers
 */
const STATE = {
  pending: false,
  users: [],
  data: null,
  modified: false, //inserted, updated, deleted
  error: null
}
const INITIAL_STATE = STATE;

// Get User
const getUser = state => {
  return {...state, pending:true, error:null}
}
const getUserSuccess = (state, { user }) => {
  // saveUser(user);// localStorage
  return {...state, data:user, pending:false, error:false}
};
const getUserFailure = (state, { error }) => {
  return {...state, pending:false, error }
};

// Get Users Conect Clinic (Admin Access)
const getUsersConectClinic = state => {
  return {...state, pending:true, data:null, users:[], error:null}
}

// Get Users By Name
const getUsersByName = state => {
  return {...state, pending:true, data:null, users:[], error:null}
}

// Get Users By Period
const getUsersByPeriod = state => {
  return {...state, pending:true, data:null, users:[], error:null}
}

// Get Users
const getUsersSuccess = (state, { users }) => {
  // saveUser(user);// localStorage
  return {...state, users, pending:false, error:false}
};
const getUsersFailure = (state, { error }) => {
  return {...state, pending:false, error }
};

// Insert Users
const insertUser = (state, {user}) => {
  return {...state, pending:true, error:null}
}
const insertUserSuccess = (state, { data }) => {
  return {...state, pending:false, data, error:false}
};
const insertUserFailure = (state, { error }) => {
  return {...state, pending: false, error }
};
// Update Users
const updateUser = (state, {user}) => {
  return {...state, pending:true, modified:false, error:null}
}
const updateUserSuccess = (state, { data }) => {
  return {...state, pending:false, data, modified:true, error:false}
};
const updateUserFailure = (state, { error }) => {
  return {...state, pending: false, error }
};

// Remove Users
const removeUser = (state, {id}) => {
  return {...state, pending:true, modified:false, error:null}
}
const removeUserSuccess = (state, { data }) => {
  return {...state, pending:false, data, modified:true, error:false}
};
const removeUserFailure = (state, { error }) => {
  return {...state, pending: false, error }
};

// Update User Password
const recoverPassword = (state, {user}) => {
  return {...state, pending:true, modified:false, error:null}
}
const recoverPasswordSuccess = (state, { data }) => {
  return {...state, pending:false, data, modified:true, error:false}
};
const recoverPasswordFailure = (state, { error }) => {
  return {...state, pending: false, error }
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.FETCH_USER]: getUser,
  [Types.FETCH_USER_SUCCESS]: getUserSuccess,
  [Types.FETCH_USER_FAILURE]: getUserFailure,
  [Types.FETCH_USERS_CONECT_CLINIC]: getUsersConectClinic,
  [Types.FETCH_USERS_BY_NAME]: getUsersByName,
  [Types.FETCH_USERS_BY_PERIOD]: getUsersByPeriod,
  [Types.FETCH_USERS_SUCCESS]: getUsersSuccess,
  [Types.FETCH_USERS_FAILURE]: getUsersFailure,
  [Types.POST_USER]: insertUser,
  [Types.POST_USER_SUCCESS]: insertUserSuccess,
  [Types.POST_USER_FAILURE]: insertUserFailure,
  [Types.PUT_USER]: updateUser,
  [Types.PUT_USER_SUCCESS]: updateUserSuccess,
  [Types.PUT_USER_FAILURE]: updateUserFailure,
  [Types.DELETE_USER]: removeUser,
  [Types.DELETE_USER_SUCCESS]: removeUserSuccess,
  [Types.DELETE_USER_FAILURE]: removeUserFailure,
  [Types.PUT_RECOVER_PASSWORD]: recoverPassword,
  [Types.PUT_RECOVER_PASSWORD_SUCCESS]: recoverPasswordSuccess,
  [Types.PUT_RECOVER_PASSWORD_FAILURE]: recoverPasswordFailure,

})