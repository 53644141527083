import jwtDecode from 'jwt-decode';
import { call, put } from 'redux-saga/effects';
import api from 'services/api';
import { Creators } from 'store/ducks/signin';

export function* signinConectClinic({ email, password }) {
  console.log('signin({ email, password })', email, password);
  try {
    const data = { email, password};
    const response = yield call(api.post, '/v1/user/signin?conectClinic=true', data);
    
    const hash = response.data.body;
    const decodded = jwtDecode(hash);

    const user = {...decodded, hash};
    
    yield put(Creators.fetchSigninSuccess(user));
  } catch (e) {
    const { response } = e;
    yield put(Creators.fetchSigninFailure(response.data));
  }
}

export function* signin({ email, password }) {
  console.log('signin({ email, password })', email, password);
  try {
    const data = { email, password};
    const response = yield call(api.post, '/v1/user/signin', data);
    
    const hash = response.data.body;
    const decodded = jwtDecode(hash);

    const user = {...decodded, hash};
    
    yield put(Creators.fetchSigninSuccess(user));
  } catch (e) {
    const { response } = e;
    yield put(Creators.fetchSigninFailure(response.data));
  }
}

export function* signout() {
  yield put(Creators.signout());
}
