import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Grid, CircularProgress } from "@material-ui/core";
import { ChevronRightSharp } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";

import Header from "pages/components/Header";
import Footer from "pages/components/Footer";
import AccountMenu from "./menu";

import { MainContent } from "./styles";

import { Title, Text, Line, CustomLink, SubTitle } from "pages/components/styles";

import { getUser } from 'services/auth';
// Actions
import { Creators as CreatorsSignin } from 'store/ducks/signin';
import { Creators as CreatorsUser } from 'store/ducks/users';

export const Index = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pending, data: userData } = useSelector(state => state.users);
  const { user } = useSelector(state => state.signin);
  
  const [userLogged, setUserLogged] = useState(null);

  useEffect(() => {
    const _user = getUser();
    setUserLogged(_user);
    if(!_user || !_user.hash) {
      navigate('/login');
    } else {
      dispatch(CreatorsUser.fetchUser(_user?.id));
    }
  },[]);

  useEffect(() => {
    const _user = getUser();
    if (!user && !_user) navigate('/');
  },[user]);

  const handleSignout = () => dispatch(CreatorsSignin.signout());

  return (
    userLogged && <div>
      <Header />

      <MainContent bgColor={'#FBFBFB'}>
        <Line>
          <Text flex='true'> <CustomLink href="/">Home</CustomLink> <ChevronRightSharp /> Conta</Text>
        </Line>
        
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <AccountMenu />
          </Grid>
          <Grid item xs={12} md={8}>
            <Title bigger>Conta</Title>
            {pending && 
              <Line>
                <CircularProgress style={{marginRight:'10px'}}/>
                <Text>Buscando dados...</Text>
              </Line>
            }

            {(!pending && !userData) &&
              <Title>Dados da conta não encontrados</Title>
            }
            
            {(!pending && userData) &&
              <>
                <div><Text big>{userData?.name}</Text></div>
                <div><Text medium>{userData?.email}</Text></div>
              </>
            }
            
          </Grid>
        </Grid>

      </MainContent>

      <Footer />
    </div>
  );
}

export default Index
